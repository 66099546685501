export const IconIstruzione = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0007 15.3452C24.5718 15.3452 24.1463 15.3538 23.7249 15.3692C18.0911 15.5753 13.3665 20.2426 13.1772 25.8065C13.164 26.1939 13.1567 26.5846 13.1567 26.9782C13.1567 28.3385 13.2435 29.6671 13.3624 30.9489C13.7251 34.8592 16.935 38.0208 20.8883 38.3935C22.2139 38.5184 23.5904 38.6111 25.0007 38.6111C26.411 38.6111 27.7875 38.5184 29.113 38.3935C33.0663 38.0208 36.2761 34.8592 36.6389 30.9489C36.7579 29.6671 36.8446 28.3385 36.8446 26.9782C36.8446 26.5846 36.8373 26.1939 36.8241 25.8065C36.635 20.2426 31.9103 15.5753 26.2765 15.3692C25.855 15.3538 25.4296 15.3452 25.0007 15.3452Z"
        fill="#386CCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8235 28.1675C34.5915 29.1675 32.3133 30.0662 30.1956 30.9015L30.1659 30.9133C27.0839 32.129 22.9848 32.1256 19.9068 30.9203L19.6836 30.8329C17.6055 30.0194 15.3674 29.1431 13.1778 28.1668C13.1642 27.7736 13.1567 27.3772 13.1567 26.9781C13.1567 26.5845 13.164 26.1938 13.1772 25.8065C13.2004 25.1237 13.2919 24.4544 13.4451 23.8052C15.9639 25.0032 18.6597 26.0587 21.2709 27.0811L21.4261 27.1419C23.5304 27.9659 26.5386 27.9664 28.6369 27.1386C31.276 26.0976 34.0013 25.0212 36.5559 23.8037C36.7094 24.4534 36.8011 25.1232 36.8241 25.8065C36.8373 26.1938 36.8446 26.5845 36.8446 26.9781C36.8446 27.3774 36.8373 27.774 36.8235 28.1675Z"
        fill="#FFA084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85801 17.8448C8.85801 16.4978 7.75145 15.4058 6.38647 15.4058C5.02149 15.4058 3.91493 16.4978 3.91493 17.8448V33.0945C2.75881 33.874 2 35.1852 2 36.6714C2 39.0619 3.96388 40.9998 6.38644 40.9998C8.80901 40.9998 10.7729 39.0619 10.7729 36.6714C10.7729 35.1852 10.0141 33.874 8.85801 33.0946V17.8448Z"
        fill="#FFA084"
      />
      <path
        d="M5.25554 15.5812C3.58079 16.6183 3.58177 18.1051 5.24898 19.1451C7.55707 20.585 9.94326 22.0802 12.716 23.4506C15.4887 24.821 18.514 26.0004 21.4272 27.1412C23.5315 27.9652 26.5397 27.9656 28.638 27.1379C31.5289 25.9975 34.5231 24.8148 37.2833 23.4506C40.0435 22.0864 42.4363 20.6065 44.7438 19.1777C46.4185 18.1406 46.4175 16.6538 44.7503 15.6138C42.4423 14.1739 40.0561 12.6787 37.2833 11.3083C34.5106 9.9379 31.4853 8.75852 28.5721 7.61777C26.4678 6.79378 23.4596 6.79329 21.3613 7.62103C18.4704 8.76138 15.4762 9.94411 12.716 11.3083C9.95582 12.6725 7.56284 14.1524 5.25554 15.5812Z"
        fill="#386CCC"
      />
    </svg>
  );
};
