import {
  currentCompanyConfigState,
  useBreakpoint,
} from '@laborability/commons';
import {
  COLORS,
  IconsSize,
  IconsStyle,
  IconSearchComponent,
  LBTButton,
  LBTButtonIcon,
  IconBurger,
  IconCross,
  LBTImage,
  LBTDialog,
} from '@laborability/components';
import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APP_ROUTES } from '../../Routes';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

const StyledStickyHeader = styled(Stack)<{
  backgroundColor: string;
  height: string;
}>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid ${COLORS.getInstance().BLACK};
  ${props => `height: ${props.height};`}
  transition: all 0.5s linear;
  left: 0;
`;
export type LBTNavMenuProps = {
  backgroundColor?: string;
};

const NavMenu: React.FC<LBTNavMenuProps> = ({
  backgroundColor = COLORS.getInstance().WHITE,
}: LBTNavMenuProps) => {
  const { logo } = useRecoilValue(currentCompanyConfigState);
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState<string>('');

  const confirmNavigate = (navigateTo: string) => {
    if (location.pathname === `/${APP_ROUTES.QUESTIONARY}`)
      setIsModalOpen(navigateTo);
    else navigate(navigateTo);
  };

  return (
    <>
      <StyledStickyHeader
        height={isDesktop ? '100px' : '64px'}
        backgroundColor={backgroundColor}
        direction="column"
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" marginLeft={'16px'}>
            <LBTButton
              datatestid="link_topbar_logo"
              onClick={() => confirmNavigate(APP_ROUTES.HOME)}
            >
              {logo ? (
                <img
                  src={logo}
                  style={{
                    maxHeight: isDesktop ? '80px' : '44px',
                    maxWidth: isDesktop ? '182px' : '122px',
                  }}
                />
              ) : (
                <LBTImage
                  image="laborabilityLogo"
                  height={isDesktop ? '24px' : '16px'}
                  width={isDesktop ? '182px' : '122px'}
                />
              )}
            </LBTButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginRight={'16px'}
          >
            <LBTButtonIcon
              datatestid="link_topbar_search"
              onClick={() => confirmNavigate(`${APP_ROUTES.SEARCH_MISURE}`)}
            >
              <IconSearchComponent
                datatestid="link_topbar_search"
                size={IconsSize.LARGE}
                style={IconsStyle.FILLED}
                color={COLORS.getInstance().BLACK}
              />
            </LBTButtonIcon>
            <LBTButtonIcon
              datatestid="link_topbar_burger"
              onClick={() => {
                if (location.pathname === `/${APP_ROUTES.MENU}`)
                  return confirmNavigate(APP_ROUTES.HOME);
                confirmNavigate(APP_ROUTES.MENU);
              }}
            >
              {location.pathname === `/${APP_ROUTES.MENU}` ? (
                <IconCross datatestid="link_topbar_burger" />
              ) : (
                <IconBurger datatestid="link_topbar_burger" />
              )}
            </LBTButtonIcon>
          </Stack>
        </Stack>
      </StyledStickyHeader>
      <LBTDialog
        open={!!isModalOpen}
        onClose={() => setIsModalOpen('')}
        onCancel={() => setIsModalOpen('')}
        onSubmit={() => {
          navigate(isModalOpen);
          setIsModalOpen('');
        }}
        title="Confermi di voler uscire dal questionario?"
      >
        Le risposte che ci hai dato fin qui sono salvate e puoi vederle dal
        menu. Puoi modificarle da lì in qualsiasi momento.
      </LBTDialog>
    </>
  );
};

export default NavMenu;
