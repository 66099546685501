import {
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
  LBTButtonIllustrative,
  LBTAlert,
  LBTButton,
  LBTDialog,
} from '@laborability/components';
import { useState } from 'react';
import { useBreakpoint, useTrackAnalytics } from '@laborability/commons';
import { Grid } from '@mui/material';
import { IconCasa } from '../../components/icons/IconCasa';
import { IconCultura } from '../../components/icons/IconCultura';
import { IconGenitorialita } from '../../components/icons/IconGenitorialita';
import { IconIstruzione } from '../../components/icons/IconIstruzione';
import { IconSalute } from '../../components/icons/IconSalute';
import { IconTrasporti } from '../../components/icons/IconTrasporti';

interface Props {
  setStep: (i: number) => void;
}

export default function MyConcessions({ setStep }: Props) {
  const { isDesktop } = useBreakpoint();
  const [showAlert, setShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Questionario - Overview misure sbloccate' },
    { key: 'page_location', value: '/onboarding/overview' },
  ]);

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={4} />
        <LBTLabel variant="delaDisplay" component="h3">
          Le tue agevolazioni
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle">
          Sblocca le agevolazioni che ti spettano rispondendo a qualche domanda
          per ogni categoria. Abbiamo già fatto una grossa scrematura grazie
          alle risposte che ci hai dato fin qui
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {showAlert && (
          <LBTAlert
            sx={{
              maxWidth: '358px',
            }}
            onClose={() => {}}
            color="error"
            variant="standard"
            title="# bonus non verificabili"
            message="Sono le agevolazioni legate alle super domande. Se ne hai saltate, ti consigliamo di rispondere anche solo con una stima"
            datatestid={''}
            customCloseComponent={
              <LBTButton
                color="error"
                size="small"
                variant="invisible"
                onClick={() => setStep(3)}
                datatestid="onboarding_button_risolvi_sblocca_agevolazioni"
              >
                RISOLVI
              </LBTButton>
            }
          />
        )}
        <LBTSpacer spacing={4} />
        <Grid
          container
          spacing={isDesktop ? '24px' : '16px'}
          style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
        >
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`onboarding_button_cat_salute_unlocked`}
              onClick={() => setStep(4)}
              icon={<IconSalute />}
              label="Salute"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_cultura_unlocked`}
              onClick={() => setStep(5)}
              icon={<IconCultura />}
              label="Cultura"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_istruzione_unlocked`}
              onClick={() => setStep(6)}
              icon={<IconIstruzione />}
              label="Istruzione"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_trasporti_unlocked`}
              onClick={() => setStep(7)}
              icon={<IconTrasporti />}
              label="Trasporti"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_genitorialita_unlocked`}
              onClick={() => setStep(8)}
              icon={<IconGenitorialita />}
              label="Genitorialità"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
          <Grid item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_casa_unlocked`}
              onClick={() => setStep(9)}
              icon={<IconCasa />}
              label="Casa"
              locked={false}
              iconBgColor={COLORS.getInstance().PRIMARY_MAIN}
            />
          </Grid>
        </Grid>
        <LBTSpacer spacing={4} />
      </Section>
      <LBTDialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Al momento in questa categoria non ci sono agevolazioni per te, ma puoi continuare a rispondere"
        onSubmitLabel="Continua"
        onCancelLabel="Salta"
        onCancel={() => setIsModalOpen(false)}
        onSubmit={() => setIsModalOpen(false)}
      >
        Oggi non hai i requisiti, ma non significa che la situazione rimarrà
        così per sempre. Durante l’anno vengono pubblicati nuovi bonus e le
        condizioni di accesso possono cambiare. Continua a rispondere per
        mantenere il profilo aggiornato.
      </LBTDialog>
    </>
  );
}
