export const IconTrasporti = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.13972 20.8324L11.5897 13.9291C12.5451 12.018 14.014 10.4108 15.8317 9.28785C17.6494 8.16485 19.744 7.57045 21.8806 7.57129H25.1597C27.9622 7.57118 30.6684 8.59446 32.7696 10.4489C34.8709 12.3033 36.2227 14.8612 36.571 17.642L37.0047 21.1019C38.3946 21.5421 39.6037 21.9496 40.6321 22.38C41.9037 22.9057 43.0044 23.507 43.8784 24.3186C45.7513 26.0469 46.1817 28.3436 46.1817 31.3599C46.1817 32.9699 45.6593 34.6719 44.874 36.0814C43.6911 38.204 41.148 38.8381 38.7166 38.8381H9.22729C6.763 38.8381 4.463 37.9609 3.20129 35.8416C2.19257 34.1527 1.82129 31.9546 1.82129 29.717C1.82129 25.531 3.50029 23.1456 5.452 21.8937C6.27242 21.3726 7.18457 21.0124 8.13972 20.8324Z"
        fill="#386CCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1169 14.9971C25.4742 15.4073 25.654 15.9425 25.6171 16.4852C25.5801 17.0279 25.3293 17.5338 24.9198 17.8918C24.3612 18.3847 23.5595 19.5577 22.7611 21.0231C22.7567 21.0297 22.7523 21.0363 22.7479 21.0428L26.1191 20.4415C26.4572 20.381 26.8051 20.4062 27.1309 20.5148C27.4568 20.6235 27.7501 20.8122 27.9842 21.0635C28.2183 21.3149 28.3855 21.621 28.4707 21.9538C28.5558 22.2865 28.5562 22.6353 28.4717 22.9683C28.0577 24.6045 27.1278 26.3953 26.2439 27.8508C25.5035 29.0915 24.6628 30.2695 23.7304 31.3731C23.5539 31.5834 23.337 31.7562 23.0925 31.8812C22.8481 32.0062 22.581 32.0809 22.3072 32.101C22.0334 32.121 21.7583 32.0859 21.4983 31.9978C21.2383 31.9096 20.9986 31.7703 20.7933 31.5879C20.5881 31.4055 20.4215 31.1838 20.3035 30.936C20.1854 30.6881 20.1182 30.4191 20.1059 30.1448C20.0936 29.8705 20.1365 29.5965 20.2319 29.3391C20.3273 29.0817 20.4734 28.846 20.6615 28.646C21.5675 27.5613 22.3715 26.3954 23.0634 25.1631L19.5937 25.7808C19.24 25.8444 18.8759 25.8141 18.5376 25.6929C18.1994 25.5717 17.8988 25.3638 17.6661 25.0901C17.4333 24.8164 17.2764 24.4865 17.211 24.1331C17.1457 23.7798 17.1742 23.4155 17.2937 23.0767C17.6091 22.1797 18.3122 20.5993 19.1534 19.0615C19.9584 17.583 21.0558 15.8185 22.2189 14.8C22.4221 14.6226 22.6583 14.487 22.9139 14.4009C23.1696 14.3148 23.4397 14.2799 23.7088 14.2982C23.9779 14.3165 24.2408 14.3876 24.4824 14.5076C24.724 14.6275 24.9396 14.7938 25.1169 14.9971Z"
        fill="#9AE6C8"
      />
      <path
        d="M13.3211 43.7897C16.4918 43.7897 18.2759 42.0089 18.2759 38.8382C18.2759 35.6674 16.4918 33.8833 13.3211 33.8833C10.1504 33.8833 8.36621 35.6674 8.36621 38.8382C8.36621 42.0089 10.1504 43.7897 13.3211 43.7897Z"
        fill="#F7C4FB"
      />
      <path
        d="M34.6785 43.7897C37.8492 43.7897 39.6333 42.0089 39.6333 38.8382C39.6333 35.6674 37.8492 33.8833 34.6785 33.8833C31.5078 33.8833 29.7236 35.6674 29.7236 38.8382C29.7236 42.0089 31.5078 43.7897 34.6785 43.7897Z"
        fill="#F7C4FB"
      />
    </svg>
  );
};
