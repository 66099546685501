import { useBreakpoint, userState } from '@laborability/commons';
import {
  Avatar,
  IconArrowRightComponent,
  IconBanner,
  IconBinoculars,
  IconPoint,
  IconQuestionary,
  IconStar,
  IconWarningComponent,
  IconsStyle,
  KeycloakContext,
  LBTChip,
  LBTDivider,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { APP_ROUTES } from '../../Routes';
import { useContext } from 'react';

export default function UserMenu() {
  const { hasQuestionaryAccess } = useContext(KeycloakContext);
  const { isDesktop } = useBreakpoint();
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  return (
    <Section>
      <LBTSpacer spacing={2} />
      {isDesktop && (
        <>
          <LBTLabel variant="delaDisplay">Menù</LBTLabel>
          <LBTSpacer spacing={4} />
        </>
      )}
      <Box component="ul" maxWidth="680px" width="100%" padding={0} margin={0}>
        {hasQuestionaryAccess && (
          <>
            <LBTListItem
              component="a"
              title="Questionario"
              datatestid="menu_questionario"
              leftItem={<IconQuestionary />}
              rightItem={
                <Stack flexDirection="row" alignItems="center">
                  <LBTChip
                    label="x%"
                    color="lemonCake"
                    sx={{ minWidth: '50px', marginRight: '10px' }}
                  />
                  <IconArrowRightComponent />
                </Stack>
              }
              sx={{ minHeight: '58px' }}
              onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY_MENU}`)}
            />
            <LBTListItem
              component="a"
              title="Le tue agevolazioni"
              datatestid="menu_agevolazioni"
              leftItem={<IconBanner />}
              rightItem={
                <Stack flexDirection="row" alignItems="center">
                  <LBTChip
                    label="x"
                    color="lemonCake"
                    sx={{ minWidth: '50px', marginRight: '10px' }}
                  />
                  <IconArrowRightComponent />
                </Stack>
              }
              sx={{ minHeight: '58px' }}
              onClick={() => navigate(`/${APP_ROUTES.MY_BONUS}`)}
            />
            <LBTListItem
              component="a"
              title="Casi particolari"
              datatestid="menu_casi_particolari"
              leftItem={<IconBinoculars />}
              rightItem={<IconArrowRightComponent />}
              sx={{ minHeight: '58px' }}
              onClick={() => navigate(`/${APP_ROUTES.SPECIAL_CASES}`)}
            />
          </>
        )}

        <LBTListItem
          component="a"
          title="Segnala un'agevolazione"
          datatestid="menu_segnala_agevolazione"
          leftItem={<IconPoint />}
          rightItem={<IconArrowRightComponent />}
          sx={{ minHeight: '58px' }}
          onClick={() => navigate(`/${APP_ROUTES.REPORT_MEASURE}`)}
        />
        <LBTListItem
          component="a"
          title="Valuta il servizio"
          datatestid="menu_valuta_servizio"
          leftItem={<IconStar />}
          rightItem={<IconArrowRightComponent />}
          sx={{ minHeight: '58px' }}
          onClick={() => navigate(`/${APP_ROUTES.RATE_SERVICE}`)}
        />
        <LBTListItem
          component="a"
          title="Segnala un problema"
          datatestid="menu_segnala_problema"
          leftItem={<IconWarningComponent style={IconsStyle.FILLED} />}
          rightItem={<IconArrowRightComponent />}
          sx={{ minHeight: '58px' }}
          onClick={() => navigate(`/${APP_ROUTES.REPORT_ISSUE}`)}
        />
      </Box>
      <Stack
        position="absolute"
        bottom={0}
        width="100%"
        display="flex"
        alignItems="center"
      >
        <LBTDivider />
        <LBTListItem
          component="a"
          title={user?.first_name ?? ''}
          titleVariant="sourceSubtitle3"
          description={user.email}
          leftItem={
            <Avatar label={user?.first_name?.charAt(0).toUpperCase() ?? ''} />
          }
          rightItem={<IconArrowRightComponent />}
          sx={{ maxWidth: '680px', width: '100%', padding: '17px 16px' }}
          onClick={() => navigate(`/${APP_ROUTES.USER_ACCOUNT}`)}
        />
      </Stack>
    </Section>
  );
}
