import {
  STATIC_QUESTIONARY_NUMBER,
  currentUserState,
  getAllQuestionFlowCallback,
  getUserProfileCallback,
  questionFlowsLoaderState,
  questionFlowsState,
  userLoaderState,
} from '@laborability/commons';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import Intro from './Intro';
import MyConcessions from './MyConcessions';
import { Flow, QuestionaryContext } from '@laborability/components';
import { useLocation } from 'react-router-dom';

export default function QuestionaryIndex() {
  const { state } = useLocation();
  const getFlows = useRecoilCallback(getAllQuestionFlowCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const flows = useRecoilValue(questionFlowsState);
  const flowsLoader = useRecoilValue(questionFlowsLoaderState);
  const userLoader = useRecoilValue(userLoaderState);
  const currentUser = useRecoilValue(currentUserState);
  const [showIntro, setShowIntro] = useState(true);
  const [step, setStep] = useState<number>(0);
  const [pageId, setPageId] = useState<number>(0);

  useEffect(() => {
    getFlows();
    getUserMe();
  }, []);

  useEffect(() => {
    // valori di default passati dall'esterno
    if (state?.step && state?.pageId) {
      setStep(state.step);
      setPageId(state.pageId);
    }
    // nessun dato, inizio dal primo questionario
    else if (!currentUser.questionary_status?.length) {
      setStep(1);
      setPageId(0);
    }
    // i primi 3 questionari sono obbligatori per procedere ai successivi
    else if (
      currentUser.questionary_status?.length <= STATIC_QUESTIONARY_NUMBER
    ) {
      const ongoingStep = currentUser.questionary_status?.find(
        item => !item.completed,
      );
      setStep(ongoingStep?.step ? ongoingStep.step : 0);
      setPageId(ongoingStep?.page_id ? ongoingStep.page_id : 0);
    }
    // mostra menù dei questionari seccessivi a quelli obbligatori
    else {
      setStep(0);
      setPageId(0);
    }
  }, [currentUser]);

  if (flowsLoader || userLoader) return null;

  if (!currentUser.questionary_status?.length && showIntro)
    return <Intro closeIntro={() => setShowIntro(false)} />;

  const flow = flows.find(item => item.step === step);

  if (step && flow)
    return (
      <QuestionaryContext.Provider value={{ pageId, setPageId, step, setStep }}>
        <Flow flow={flow} />
      </QuestionaryContext.Provider>
    );

  return <MyConcessions setStep={setStep} />;
}
