import {
  UserAnswersFlow,
  clearResponsesCallback,
  useBreakpoint,
} from '@laborability/commons';
import {
  COLORS,
  IconHappyFaceComponent,
  IconsSize,
  IconsStyle,
  LBTAlert,
  LBTButton,
  LBTDialog,
  LBTDivider,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import { Fragment, useState } from 'react';
import { useRecoilCallback } from 'recoil';

interface Props {
  flow: UserAnswersFlow;
  onClose: () => void;
}

export default function AnswerReview({ flow, onClose }: Props) {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const clearResponses = useRecoilCallback(clearResponsesCallback, []);

  return (
    <>
      <Section>
        <LBTSpacer spacing={2} />
        <Stack
          direction="row"
          alignItems="center"
          component="a"
          sx={{ cursor: 'pointer' }}
        >
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.85618 7.195L0.307177 3.75V3.698L3.85618 0.252999L4.40218 0.851L1.78918 3.282H7.49618V4.166H1.78918L4.40218 6.597L3.85618 7.195Z"
              fill={COLORS.getInstance().PRIMARY_SUPERDARK}
            />
          </svg>
          <LBTLabel
            variant="smallCaps"
            color={COLORS.getInstance().PRIMARY_SUPERDARK}
            onClick={onClose}
          >
            Questionario
          </LBTLabel>
        </Stack>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay" component="h1">
          Profilo personale
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {flow.pages.map((page, pageIndex) => (
          <Fragment key={page.page_id}>
            {page.answers.map((answer, answerIndex) => (
              <LBTListItem
                key={`${answer.question_id}-${answer.entity_id}`}
                title={answer.attribute_name}
                description={answer.answer}
                sx={{ maxWidth: '680px', padding: '8px 16px' }}
                rightItem={
                  answerIndex === 0 ? (
                    <LBTLabel
                      variant="bodyText"
                      component="a"
                      onClick={() =>
                        navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                          state: { step: flow.step, pageId: page.page_id },
                        })
                      }
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                      }}
                    >
                      Modifica
                    </LBTLabel>
                  ) : undefined
                }
              />
            ))}
            {flow.pages.length !== pageIndex + 1 && (
              <LBTDivider sx={{ maxWidth: '680px', width: '100%' }} />
            )}
          </Fragment>
        ))}
        <LBTSpacer spacing={2} />
        {flow?.image && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ width: '100%', maxWidth: !isDesktop ? '100%' : '328px' }}
              src={flow.image}
            />
          </Box>
        )}
        <LBTSpacer spacing={2} />
        {flow.position !== flow.number_of_pages && (
          <>
            {flow.step === 3 ? (
              <LBTAlert
                title="X bonus non verificabili"
                message="Sono le agevolazioni legate alle super domande. Se ne hai saltate, ti consigliamo di rispondere anche solo con una stima"
                variant="standard"
                color="error"
              />
            ) : (
              <LBTAlert
                title={`Completamento ${Math.round((flow.position * 100) / (flow.number_of_pages ?? 1))}%`}
                message="Continua a rispondere alle domande della categoria per sbloccare tutte le agevolazioni che ti spettano"
                variant="standard"
                color="warning"
              />
            )}
            <LBTSpacer spacing={2} />
            <LBTButton
              variant="contained"
              fullWidth
              onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY}`)}
            >
              {flow.step === 3
                ? 'Rispondi alle super domande'
                : 'Continua a rispondere'}
            </LBTButton>
            <LBTSpacer spacing={2} />
          </>
        )}
        <div style={{ maxWidth: '504px', display: 'flex', gap: '8px' }}>
          <div>
            <IconHappyFaceComponent
              size={IconsSize.LARGE}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().SUCCESS_SUPERDARK}
            />
          </div>
          <LBTLabel
            variant="inputHelper"
            color={COLORS.getInstance().SUCCESS_SUPERDARK}
            textAlign="left"
          >
            Le informazioni che ci dai sono preziose e le tratteremo con la
            massima riservatezza. I dati che raccogliamo servono a selezionare
            le agevolazioni giuste per te. Nessuno (nemmeno noi) potrà mai
            risalire alla tua identità
          </LBTLabel>
        </div>
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => setIsDeleteModalOpen(true)}
          variant="invisible"
        >
          Elimina i dati di questa sezione
        </LBTButton>
        <LBTSpacer spacing={6} />
      </Section>
      <LBTDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confermi di voler eliminare i dati di questa sezione?"
        onSubmitLabel="Elimina dati"
        onSubmit={async () => {
          await clearResponses({ id: flow.flow_id });
          setIsDeleteModalOpen(false);
          onClose();
        }}
      >
        Cancelleremo tutte le risposte della categoria. Se vuoi sbloccare le
        agevolazioni di questa sezione, dovrai rispondere di nuovo alle domande
        del questionario.
      </LBTDialog>
    </>
  );
}
