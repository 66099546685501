import {
  reportProblemCallback,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTButton,
  LBTLabel,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import ThankYouPage from '../../components/ThankYouPage';

export default function IssueReport() {
  const sendReport = useRecoilCallback(reportProblemCallback, []);
  const descriptionMaxLength = 800;
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [values, setValues] = useState<{ name: string; description: string }>({
    name: '',
    description: '',
  });
  const [errors, setErrors] = useState<{ name: boolean; description: boolean }>(
    {
      name: false,
      description: false,
    },
  );

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Segnala un problema' },
    { key: 'page_location', value: '/menu/segnala-problema/' },
  ]);

  return (
    <Section>
      {isReportCompleted ? (
        <ThankYouPage
          title="Grazie per la segnalazione"
          subtitle="Prendersi del tempo per segnalare i problemi di un servizio non è scontato, quindi ti ringraziamo veramente tanto. Non riusciamo a rispondere a tutti i messaggi ma li leggiamo con attenzione e prendiamo in considerazione tutte le segnalazioni"
        />
      ) : (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="delaDisplay" component="h1">
            Segnala un problema
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Utilizza il form per farci sapere se stai riscontrando dei problemi
            oppure se vuoi darci un parere sul servizio. Non riusciamo a
            rispondere a tutti i messaggi, ma li leggiamo con attenzione e sono
            per noi preziosissimi
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Oggetto"
            type="text"
            name="name"
            onChange={value => setValues({ ...values, name: value ?? '' })}
            value={values.name}
            required
            error={errors.name}
            helperText={errors.name ? 'Campo obbligatorio' : undefined}
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Descrizione"
            type="text"
            name="description"
            onChange={value => {
              setErrors({
                ...errors,
                description: descriptionMaxLength - (value?.length ?? 0) < 0,
              });
              setValues({ ...values, description: value ?? '' });
            }}
            value={values.description}
            required
            error={errors.description}
            helperText={
              errors.description && !values.description?.length
                ? 'Campo obbligatorio'
                : `${descriptionMaxLength - (values?.description?.length ?? 0)} caratteri disponibili`
            }
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTButton
            variant="contained"
            disabled={errors.name || errors.description}
            onClick={async () => {
              const res = await sendReport({
                title: values.name,
                description: values.description,
              });
              if (res?.data) setIsReportCompleted(true);
            }}
            fullWidth
          >
            Avanti
          </LBTButton>
          <LBTSpacer spacing={4} />
        </>
      )}
    </Section>
  );
}
