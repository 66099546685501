import {
  QUESTIONARY_STEPS,
  QuestionFlow,
  initFlowCallback,
  slugify,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTAlert,
  LBTButton,
  LBTLabel,
  LBTSpacer,
  QuestionaryContext,
  Section,
} from '@laborability/components';
import { useRecoilCallback } from 'recoil';
import { useContext } from 'react';
import Page from './Page';
import { Box } from '@mui/material';

interface Props {
  flow: QuestionFlow;
}

export default function Flow({ flow }: Props) {
  const { isDesktop } = useBreakpoint();
  const { pageId, setPageId } = useContext(QuestionaryContext);
  const init = useRecoilCallback(initFlowCallback, []);

  const initQuestionary = async (id: number) => {
    const res = await init({ id });
    if (!res?.data?.id) return;
    setPageId(res.data.id);
  };
  useTrackAnalytics(
    flow.name
      ? [
          { key: 'event', value: 'page_view' },
          { key: 'page_title', value: `Questionario - ${flow.name}` },
          { key: 'page_location', value: `/onboarding/${slugify(flow.name)}` },
        ]
      : [],
  );

  if (!pageId)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="sourceSubtitle2">
          Passo {flow.step}/{QUESTIONARY_STEPS}
        </LBTLabel>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay">{flow.name}</LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle">{flow.description}</LBTLabel>
        <LBTSpacer spacing={2} />
        {flow?.image && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ width: '100%', maxWidth: !isDesktop ? '100%' : '328px' }}
              src={flow.image}
            />
          </Box>
        )}
        <LBTSpacer spacing={2} />
        <LBTAlert
          title={flow?.info_title!}
          message={flow?.info_text!}
          variant="standard"
          color="success"
          sx={{ width: '100%' }}
        />
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => initQuestionary(flow.id!)}
          variant="contained"
          size="large"
          fullWidth
        >
          Avanti
        </LBTButton>
        <LBTSpacer spacing={4} />
      </Section>
    );
  return <Page flow={flow} />;
}
