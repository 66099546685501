export const IconGenitorialita = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3241_1001)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8976 1C28.4611 1 28.0426 1.17504 27.734 1.4866C27.4253 1.79817 27.252 2.22075 27.252 2.66137V15.9523C27.252 16.393 27.4253 16.8155 27.734 17.1271C28.0426 17.4387 28.4611 17.6137 28.8976 17.6137C29.334 17.6137 29.7526 17.4387 30.0612 17.1271C30.3699 16.8155 30.5432 16.393 30.5432 15.9523H43.7084C43.7084 16.393 43.8818 16.8155 44.1904 17.1271C44.499 17.4387 44.9176 17.6137 45.354 17.6137C45.7905 17.6137 46.2091 17.4387 46.5177 17.1271C46.8263 16.8155 46.9997 16.393 46.9997 15.9523C46.9997 13.9888 46.6166 12.0444 45.8723 10.2303C45.128 8.41622 44.037 6.76789 42.6617 5.37944C41.2864 3.99098 39.6537 2.8896 37.8567 2.13818C36.0598 1.38675 34.1339 1 32.1889 1L28.8976 1Z"
          fill="#FFA084"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.20943 8.06402C9.13166 7.62868 8.93729 7.22315 8.6474 6.89142C8.35751 6.5597 7.98317 6.31446 7.56501 6.1823C7.14684 6.05013 6.70081 6.0361 6.2753 6.14172C5.84979 6.24735 5.46106 6.46859 5.15127 6.78145C4.68334 7.22164 4.06442 7.46129 3.42492 7.4499C2.78542 7.43851 2.17526 7.17697 1.723 6.72038C1.27073 6.26379 1.01167 5.64781 1.00038 5.00219C0.989101 4.35658 1.22648 3.73175 1.66251 3.25934C5.84903 -0.973829 13.0175 1.29892 14.0674 7.18017L15.6307 15.9522H47V19.2749C47 24.6711 44.1432 28.1134 40.4503 30.0938C36.8595 32.021 32.4492 32.6157 28.8979 32.5659C25.3499 32.6157 20.9396 32.0243 17.3455 30.0938C13.6593 28.1134 10.7958 24.6711 10.7958 19.2749V17.6136C10.796 17.4826 10.8114 17.3521 10.8419 17.2248L9.20943 8.06402Z"
          fill="#386CCC"
        />
        <path
          d="M19.0242 46.9999C22.9079 46.9999 25.0933 44.7936 25.0933 40.8727C25.0933 36.9519 22.9079 34.7456 19.0242 34.7456C15.1405 34.7456 12.9551 36.9519 12.9551 40.8727C12.9551 44.7936 15.1405 46.9999 19.0242 46.9999Z"
          fill="#80ABF9"
        />
        <path
          d="M38.7713 46.9999C42.655 46.9999 44.8404 44.7936 44.8404 40.8727C44.8404 36.9519 42.655 34.7456 38.7713 34.7456C34.8876 34.7456 32.7021 36.9519 32.7021 40.8727C32.7021 44.7936 34.8876 46.9999 38.7713 46.9999Z"
          fill="#80ABF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_3241_1001">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
