import { snackBarState, useKeycloak, useStartup } from '@laborability/commons';
import md5 from 'md5';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet } from 'react-router-dom';
import { initializeTagManager } from '../utils/gtm';
import { KeycloakContext } from '@laborability/components';
import { currentCompanyConfigState } from '@laborability/commons';
import Loader from './Loader';
import { useRecoilValue } from 'recoil';

export default function Auth() {
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();

  const currentCompany = useRecoilValue(currentCompanyConfigState);
  const { code } = useRecoilValue(snackBarState);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  useEffect(() => {
    initializeTagManager();
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      onStartup();
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          id_user: md5(keycloak?.tokenParsed?.preferred_username),
          company: `company-${currentCompany.id ?? 'id'}`,
          timestamp: new Date().toISOString(),
          is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
        },
      });
      console.log('@@@tagManager', {
        event: 'login',
        company: `company-${currentCompany.id ?? 'id'}`,
        id_user: md5(keycloak?.tokenParsed?.preferred_username),
        timestamp: new Date().toISOString(),
        is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
      });
    }
  }, [currentCompany.id]);

  /*

  useTrackAnalytics(
    keycloak?.tokenParsed
      ? [
          {
            key: 'id_user',
            value: md5(keycloak?.tokenParsed?.preferred_username),
          },
          {
            key: 'user_test',
            value: TEST_DOMAINS.includes(domain) ? 'true' : 'false',
          },
        ]
      : [],
    'login',
  );

  */

  return (
    <KeycloakContext.Provider
      value={{
        keycloak,
        hasQuestionaryAccess:
          !!process.env.REACT_APP_FE_QUESTIONARY_ACCESS?.split(', ').find(
            azienda => azienda === currentCompany.realm,
          ),
      }}
    >
      {isCompletedStartup ? <Outlet /> : <Loader />}
    </KeycloakContext.Provider>
  );
}
